import React, { useState } from 'react';
import { Table, Input, Button, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// const dataSource = [
//     {
//         key: '1',
//         name: 'John Doe',
//         age: 32,
//         address: 'New York',
//         ribbon: 'VIP',
//     },
//     {
//         key: '2',
//         name: 'Jane Smith',
//         age: 28,
//         address: 'London',
//         ribbon: 'New',
//     },
//     // Add more data here
// ];

// const columns = [
//     {
//         title: <Row gutter={5} justify={'space-around'} align={'middle'}>
//             <Col span={2}>Search:</Col>
//             <Col span={10}>
//                 <Search onSearch={searchHandler} allowClear />
//             </Col>
//         </Row>,
//         children: [
//             {
//                 title: 'Policy Name | No.',
//                 dataIndex: 'name',
//                 key: 'name',
//                 render: (__, record) => <><strong>{__?.toUpperCase()}</strong> <br></br><strong>{record?.policy_no}</strong></>
//             },
//             {
//                 title: 'Premium',
//                 dataIndex: 'premium_type',
//                 key: 'premium_type',
//                 render: (__, record) => <>{record?.insurance_package?.toUpperCase()}<br></br>{__?.toUpperCase()}</>
//             },
//             {
//                 title: 'Enrollees (Active of Size)',
//                 dataIndex: 'enrolee_size',
//                 key: 'enrolee_size',
//                 render: (__, record) => <>{record?.active_enrollees} of {__}</>
//             },
//             {
//                 title: 'Date Joined',
//                 dataIndex: 'date',
//                 key: 'date',
//                 render: (__, record) => (new Date(__)).toDateString()
//             },
//             {
//                 title: 'Status | Action',
//                 key: 'status',
//                 render: (__, record) => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                     <Space>{record.status ? <CheckOutlined style={{ color: 'blue' }} /> : <CloseOutlined style={{ color: 'red' }} />}</Space>
//                     <Divider type="vertical" style={{ border: '1px solid black' }} />
//                     <Space>
//                         <Button type="link" icon={<SettingOutlined />} onClick={() => handleView(record)} />
//                         <Button type="link" icon={<UsergroupAddOutlined />} onClick={() => handleViewEnrollees(record)} />
//                     </Space>
//                 </div>,
//             },
//         ]
//     }
// ];

const SearchableTable = ({columns, dataSource, renderRow, loading, expandable, ...props}) => {
    // const [searchText, setSearchText] = useState('');
    // const [searchedColumn, setSearchedColumn] = useState('');

    // const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm();
    //     setSearchText(selectedKeys[0]);
    //     setSearchedColumn(dataIndex);
    // };

    // const handleReset = clearFilters => {
    //     clearFilters();
    //     setSearchText('');
    // };

    // const renderRow = (record) => {
    //     return (
    //         <Tag.Ribbon text={record?.ribbon} color="blue">
    //             <div>{record?.name}</div>
    //         </Tag.Ribbon>
    //     );
    // };

    return (
        <Table
            dataSource={dataSource}
            columns={columns.map(col => {
                if (!col.filterDropdown) {
                    return col;
                }
                return {
                    ...col,
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => searchInput.select(), 100);
                        }
                    },
                };
            })}
            pagination={dataSource?.length > 10 && { pageSize:  10 }}
            rowKey="key"
            rowClassName="editable-row"
            renderItem={renderRow}
            expandable={expandable && {
                expandedRowRender: record => renderRow(record),
            }}
            loading={loading}
            {...props}
        />
    );
};

export default SearchableTable;
