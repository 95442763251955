import {
  Avatar,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { uri_img } from "../../assets/utils/http-request";
import lib, { calculateAgeAlt } from "../../assets/utils/lib";
import {
  CheckOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { states, gender, role, title } from "../../assets/utils/config";
import { NullSafe } from "../../assets/utils/Helpers";

const { TextArea } = Input;
const { Option } = Select;

export const EnroleeBiodata = ({ data }) => {
  const [show, setShow] = useState(false);

  const launch = () => {
    setShow(true);
  };

  return (
    <>
      <Row
        style={{
          padding: "10px",
          borderBottom: "1px solid black",
          borderTop: "1px solid black",
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={() => launch(data, "enrollee")}
      >
        <Col span={2}>
          <Row>
            <Col span={24}>
              <Avatar
                size={64}
                icon={
                  <img
                    src={`${uri_img}${data?.pic_name}`}
                    alt={data?.first_name}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={22}>
          <Row>
            <Col span={12}>
              <Row>
                <Col span={8}>Full Name: </Col>
                <Col span={16}>
                  {data?.first_name} {data?.middle_name}{" "}
                  {data?.surname?.toUpperCase()}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={8}>Role: </Col>
                <Col span={16}>{data?.role?.toUpperCase()}</Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={8}>State / LGA: </Col>
                <Col span={16}>
                  {data?.state?.toUpperCase()} / {data?.lga?.toUpperCase()}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={10}>Policy No.: </Col>
                <Col span={14}>{data?.policy_no}</Col>
              </Row>
              <Row>
                <Col span={10}>Insurance No.: </Col>
                <Col span={14}>{data?.insurance_no}</Col>
              </Row>
            </Col>
            <Col span={18}>
              <Row>
                <Col span={8}>Primary Heath Facility: </Col>
                <Col span={16}>{data?.primary_health_facility}</Col>
              </Row>
              <Row>
                <Col span={8}>Altenative Health Facility: </Col>
                <Col span={16}>{data?.secondary_health_facility}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={8}>Age: </Col>
                <Col span={16}>
                  {data?.date_of_birth
                    ? calculateAgeAlt(data?.date_of_birth)
                    : "Invalid Age"}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <EnroleeBiodataModal
        // type={type}
        data={data}
        display={show}
        action={() => setShow(false)}
      />
    </>
  );
};

const EnroleeBiodataModal = ({ type, data, display, action }) => {
  const [n_data, setNdata] = useState(data);
  const [edit, setEdit] = useState(false);
  const [, setUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [loadingActivate, setLoadingActivate] = useState(false);
  const [loadingRevoke, ] = useState(false);
  const [, setOrg] = useState(false);

  const [stateHealthCare, setStateHealtCare] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providerError, setProviderError] = useState(false);

  useEffect(() => {
    if (n_data?.social_no) {
      handleFocusOut();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const vn = Object.entries(n_data).filter((v, i) => v[1] !== data[v[0]]);
    if (vn.length) setUpdate(true);
  }, [n_data, data]);

  const updateData = async (e, data) => {
    if (providerError) {
      notification.error({
        message: "There is an error preventing this action",
      });
      return;
    }

    const targ = e;
    if (Number(targ) === 1) {
      setLoadingUpdate(true);
    } else if (Number(targ) === 2) {
      setLoadingActivate(true);
    } else if (Number(targ) === 3) {
      setLoadingDisable(true);
    } else if (Number(targ) === 4) {
      setLoadingFlag(true);
    }

    setEdit(false);

    lib
      .updateUserData({ ...n_data, ...data })
      .then((res) => res.data)
      .then((data) => {
        if (!data?.error) {
          notification.success({
            message: data?.message,
            placement: "topLeft",
          });
        } else {
          notification.error({
            message: data?.message,
            placement: "topLeft",
          });
        }
      })
      .catch((err) => {
        setEdit(true);
      })
      .finally(() => {
        if (Number(targ) === 1) {
          setLoadingUpdate(false);
        } else if (Number(targ) === 2) {
          setLoadingActivate(false);
        } else if (Number(targ) === 3) {
          setLoadingDisable(false);
        } else if (Number(targ) === 4) {
          setLoadingFlag(false);
        }

        setUpdate(false);
        action();
      });
  };

  const handleFocusOut = async (e) => {
    let obj = {
      social_no: n_data?.social_no,
      mode: "corporate",
    };

    let reqData = await lib.validatSocial(obj);

    if (reqData.status === "error") {
      notification.error({ message: reqData.msg, placement: "topLeft" });
    }

    if (reqData?.error === 0) {
      setOrg(reqData?.data);
    }
  };

  const fetchProviders = async (e = null) => {
    let obj = {
      state: e || n_data?.state,
      plan_type: n_data?.insurance_package,
    };

    setLoading(true);
    let reqData = await lib.getProviders(obj);

    if (parseInt(reqData?.error) === 1) {
      setProviderError(true);
      setLoading(false);
      notification.error({
        message: reqData?.message,
        duration: 3,
        placement: "topLeft",
      });
    }

    if (parseInt(reqData?.error) === 0) {
      setStateHealtCare(NullSafe(reqData?.data));
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <>
          User Data - {data?.policy_no} |{" "}
          <Button type="primary" danger={!Number(data?.status)}>
            {Number(data?.status) ? "Active" : "Inactive"}
          </Button>
        </>
      }
      centered
      open={display}
      onOk={action}
      onCancel={action}
      footer={
        <>
          {Number(data?.status) ? (
            <Button
              type="primary"
              disabled={
                loadingUpdate ||
                loadingFlag ||
                loadingDisable ||
                loadingActivate ||
                loadingRevoke
              }
              style={{ backgroundColor: "grey", border: "1px solid red" }}
              onClick={() => updateData(3, { status: 0 })}
            >
              {loadingDisable ? <LoadingOutlined /> : <>Disable</>}
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                disabled={
                  loadingUpdate ||
                  loadingFlag ||
                  loadingDisable ||
                  loadingActivate ||
                  loadingRevoke
                }
                onClick={() => updateData(2, { status: 1, update_required: 0 })}
              >
                {loadingActivate ? <LoadingOutlined /> : <>Activate</>}
              </Button>
              <Button
                type="primary"
                danger
                disabled={
                  loadingUpdate ||
                  loadingFlag ||
                  loadingDisable ||
                  loadingActivate ||
                  loadingRevoke
                }
                onClick={() =>
                  updateData(5, {
                    status: 0,
                    update_required: 1,
                    updated: 0,
                  })
                }
              >
                {loadingRevoke ? <LoadingOutlined /> : <>Revoke</>}
              </Button>
            </>
          )}
          <Button
            type="dashed"
            disabled={
              loadingUpdate ||
              loadingFlag ||
              loadingDisable ||
              loadingActivate ||
              loadingRevoke
            }
            danger
            onClick={action}
          >
            Close
          </Button>
        </>
      }
      width={800}
      destroyOnClose
    >
      <Row style={{marginTop: 20}}>
        <Col span={24}>
          <Row gutter={[16, 8]}>
            <Col span={19}>
              <Row>
                <Col span={10}>Title:</Col>
                <Col span={14}>
                  <Select
                    disabled={!edit}
                    value={n_data?.title}
                    onChange={(e) => setNdata((d) => ({ ...d, title: e }))}
                    options={title}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={10}>First Name:</Col>
                <Col span={14}>
                  <Input
                    disabled={!edit}
                    value={n_data?.first_name}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, first_name: e.target.value }))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={10}>Middle Name:</Col>
                <Col span={14}>
                  <Input
                    disabled={!edit}
                    value={n_data?.middle_name}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, middle_name: e.target.value }))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={10}>Surname:</Col>
                <Col span={14}>
                  <Input
                    disabled={!edit}
                    value={n_data?.surname}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, surname: e.target.value }))
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={5}>
                <Avatar
                    size={140}
                    shape="square"
                    icon={
                    <img src={`${uri_img}${data?.pic_name}`} alt={data?.first_name?.slice(0, 2)?.toUpperCase()} />
                    }
                />
            </Col>
            <Col span={24}>
              <Row>
                <Col span={8}>Gender:</Col>
                <Col span={16}>
                  <Select
                    disabled={!edit}
                    value={n_data?.gender}
                    onChange={(e) => setNdata((d) => ({ ...d, gender: e }))}
                    options={gender}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>Age: </Col>
                <Col span={16}>
                  <Input
                    value={
                      n_data?.date_of_birth
                        ? calculateAgeAlt(n_data?.date_of_birth)
                        : "Invalid Age"
                    }
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>Phone:</Col>
                <Col span={16}>
                  <Input
                    disabled={!edit}
                    value={n_data?.phone}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, phone: e.target.value }))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>Role:</Col>
                <Col span={16}>
                  <Select
                    disabled={!edit}
                    value={n_data?.role}
                    onChange={(e) => setNdata((d) => ({ ...d, role: e }))}
                    options={role}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>Address:</Col>
                <Col span={16}>
                  <TextArea
                    disabled={!edit}
                    value={n_data?.contact_address}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, address: e.target.value }))
                    }
                    rows={3}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>State:</Col>
                <Col span={16}>
                  <Select
                    disabled={!edit}
                    value={n_data?.state}
                    onChange={(e) => {
                      setNdata((d) => ({ ...d, state: e }));
                      fetchProviders(e);
                    }}
                    options={states}
                    style={{ width: "100%" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>City:</Col>
                <Col span={16}>
                  <Input
                    disabled={!edit}
                    value={n_data?.lga}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, lga: e.target.value }))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ color: providerError && "red" }}>
                  Primary Health Facility:
                </Col>
                <Col span={16}>
                  <Select
                    disabled={!edit}
                    style={{
                      width: loading ? "95%" : "100%",
                      color: providerError && "red",
                    }}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, primary_health_facility: e }))
                    }
                    value={n_data?.primary_health_facility}
                  >
                    {stateHealthCare?.map((item) => (
                      <>
                        <Option
                          value={item.facility_name}
                          title={
                            item.facility_name +
                            " - " +
                            item.facility_plan_type.toUpperCase()
                          }
                          style={{ borderBottom: "1px solid #91caff" }}
                        >
                          <span>
                            {item.facility_name}{" "}
                            <Divider
                              type="vertical"
                              style={{ margin: 5, borderColor: "#001d66" }}
                            />{" "}
                            {item.facility_plan_type.toUpperCase()}
                          </span>
                          <Divider style={{ margin: 0 }} />
                          <span>Address: {item.facility_address}</span>
                          <Divider style={{ margin: 0 }} />
                          <span>
                            State: {item.facility_state}{" "}
                            <Divider
                              type="vertical"
                              style={{ margin: 5, borderColor: "#001d66" }}
                            />{" "}
                            City: {item.facility_city.toUpperCase()}
                          </span>
                        </Option>
                      </>
                    ))}
                  </Select>
                  {loading && <Spin size="small" />}
                </Col>
              </Row>
              <Row>
                <Col span={8} style={{ color: providerError && "red" }}>
                  Alternative Health Facility:
                </Col>
                <Col span={16}>
                  <Select
                    disabled={!edit}
                    style={{
                      width: loading ? "95%" : "100%",
                      color: providerError && "red",
                    }}
                    onChange={(e) =>
                      setNdata((d) => ({ ...d, secondary_health_facility: e }))
                    }
                    value={n_data?.secondary_health_facility}
                  >
                    {stateHealthCare?.map((item) => (
                      <>
                        <Option
                          value={item.facility_name}
                          title={
                            item.facility_name +
                            " - " +
                            item.facility_plan_type.toUpperCase()
                          }
                          style={{ borderBottom: "1px solid #91caff" }}
                        >
                          <span>
                            {item.facility_name}{" "}
                            <Divider
                              type="vertical"
                              style={{ margin: 5, borderColor: "#001d66" }}
                            />{" "}
                            {item.facility_plan_type.toUpperCase()}
                          </span>
                          <Divider style={{ margin: 0 }} />
                          <span>Address: {item.facility_address}</span>
                          <Divider style={{ margin: 0 }} />
                          <span>
                            State: {item.facility_state}{" "}
                            <Divider
                              type="vertical"
                              style={{ margin: 5, borderColor: "#001d66" }}
                            />{" "}
                            City: {item.facility_city.toUpperCase()}
                          </span>
                        </Option>
                      </>
                    ))}
                  </Select>
                  {loading && <Spin size="small" />}
                </Col>
              </Row>
              <Row>
                <Col span={8}>Existing Medical Condition:</Col>
                <Col span={16}>
                  <TextArea
                    disabled={!edit}
                    value={n_data?.existing_medical_condition}
                    onChange={(e) =>
                      setNdata((d) => ({
                        ...d,
                        existing_medical_condition: e.target.value,
                      }))
                    }
                    rows={3}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={8}>Pre-existing Medical Condition:</Col>
                <Col span={16}>
                  <TextArea
                    disabled={!edit}
                    value={n_data?.previous_medical_condition}
                    onChange={(e) =>
                      setNdata((d) => ({
                        ...d,
                        previous_medical_condition: e.target.value,
                      }))
                    }
                    rows={3}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="end" gutter={[16, 16]}>
                <Col
                  span={8}
                  style={{ textAlign: "right", alignContent: "flex-end" }}
                >
                  {
                    // Number(data?.status)?
                    edit ? (
                      <CheckOutlined
                        onClick={() => setEdit(false)}
                        style={{ fontSize: 20, color: "blue" }}
                        title="Done"
                      />
                    ) : (
                      <EditOutlined
                        onClick={() => setEdit(true)}
                        style={{ fontSize: 20, color: "blue" }}
                      />
                    )
                    // : null
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

// export default SearchDataBioEnrolee;
