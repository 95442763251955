import Request from './http-request';
import config from './config';

import {
    remove_user
} from '../../stores/user';

const Helpers = {};

// get header config
Helpers.getHeaderConfig = (token) => {
    return { headers: { 'Authorization': `Bearer ${token}` } }
};

// get header config
Helpers.getHeaderAccessControl = () => {
    return { headers: { 'Access-Control-Allow-Origin': '*' } }
};


// Add use to the store state
Helpers.loadUserInStore = (user) => {
    // dispatcher({type: Actions.user.set, payload: { user }});
};

// Get user from local storage
Helpers.GetUserFromStorage = () => {
    const key = config.key.user;
    const json = localStorage.getItem(key);
    // userData
    try {
        if (json && json !== null) {
            return JSON.parse(json);
        }
    } catch (e) {
        return null;
    }
};

// Add user to local storage
Helpers.SetUserInStorage = (user, set) => {
    set(user);
};

// Remove user data from local storage
Helpers.RemoveUserFromStorage = (set) => {
    set(null)
};

// Add use to the store state
Helpers.logout = (set, dispatcher) => {
    dispatcher(remove_user)
    set(null)
};

/**
 * authenticate user
 */
Helpers.signin = async (data) => {
    return await (await Request.post(config.api.login, data)).data.data;
}

/**
 * clear session if token has expired 
 */
Helpers.sessionHasExpired = (set, msg, setError) => {
    if (msg?.toUpperCase() === 'UNAUTHENTICATED') {
        Helpers.logout(set);
    } else {
        if (typeof setError === 'function') {
            setError(msg);
        }
    }
}

// Get error message from Http request
Helpers.GetHttpRequestErrorMsg = (e) => {
    return e?.response?.data?.err[0]
        || e?.response?.data?.msg
        || e?.message;
}

// Handles http request error message
Helpers.errorHandler = (set, e, notifications) => {
    const msg = Helpers.GetHttpRequestErrorMsg(e);
    if (msg?.toUpperCase() === "UNAUTHENTICATED") {
        Helpers.logout(set)
        return;
    }
    if (notifications) {
        Helpers.alert({ notifications, icon: 'error', message: msg, color: 'red' })
    }
}

/**
 * icon - success | error
 * color - green | red
 * message - message
 */
// Helpers.alert = ({notifications, icon, color, message }) => {
//     notifications.showNotification({
//         title: 'Notification',
//         icon: icon === 'success' ? <CheckCircledIcon /> : <CrossCircledIcon />,
//         color: color || 'green',
//         message,
//     });
// }


export const NullSafe = (data) => {
    return data?.map(c => {
        const this_dat = {};
        Object.entries(c).forEach(col => {
            this_dat[col[0]] = col[1] || '';
        })
        return this_dat;
    })
}

export function thereIsAnyEmptyField(obj) {
    return Object.values(obj).some(val => val === undefined || val === null || val === '');
}

// export function keysHaveEmptyValues(obj, keysArray) {
//     const result = keysArray.every(key => obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null && obj[key] !== "");
//     console.log(result);
//     return result;
// }

export function keysHaveEmptyValues(obj, keysArray) {
    for (let i of keysArray) {
        if (!obj.hasOwnProperty(i) || !obj[i])
            return true;
    }
    return false;
}

export function hasEmptyEntries(obj) {
    return Object.entries(obj).some(([key, value]) => value === "");
}

export function validateEmailDomain(email, domain = 'amanhmo.com') {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
        return { isValid: false, error: 'Invalid email format' };
    }

    const emailDomain = email.split('@')[1].toLowerCase();

    if (emailDomain !== domain.toLowerCase()) {
        return { isValid: false, error: `Email does not match the domain ${domain}` };
    }

    return { isValid: true };
}

export async function downloadFile(url, filename) {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Error: ${response.statusText}`);
    const blob = await response.blob();
    const link = Object.assign(document.createElement('a'), {
        href: URL.createObjectURL(blob),
        download: filename
    });
    link.click();
    URL.revokeObjectURL(link.href);
    return { success: true, message: 'Downnload Completed' }
}

export default Helpers;