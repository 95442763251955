import PageTitleHook from "../pagetitle"
import { Button, Input, Row, Empty, Col, Typography, Avatar, Divider, Space, Drawer, notification, FloatButton } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../assets/utils/useAuth';
import './checkins.css'
import { uri_img } from "../../assets/utils/http-request";
import { myRequestObj } from "../../assets/utils/lib";
import Helpers from "../../assets/utils/Helpers";
import { useDispatch } from "react-redux";
import { selectclient } from "../../stores/verify";
import Loader from "../compoinents/loader";
import { ReloadOutlined } from "@ant-design/icons";

const CheckinListHook = props => {
  const extended = props.extended !== undefined ? props.extended : true;
  const navigate = useNavigate();
  const { user, set } = useAuth();
  const [checkins, setCheckins] = useState([]);
  const [checkinsCopy, setCheckinsCopy] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState('');
  const dispatcher = useDispatch(selectclient);
  const [loading, setLoading] = useState(false)

  const checkinFetchInterval = useRef();
  const checkinFetchTimeout = useRef();

  useEffect(() => {
    if (!user) {
      navigate('/login')
    } else {
      if (![1, 2, 3, 4].includes(Number(user?.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigate('/dashboard')
      }
    }
  })

  useEffect(() => {
    // fetchCheckins();
    // updateCheckins()
    // return () => clearIntervals();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterClaimsList(filterKeyword);
  }, [filterKeyword]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterClaimsList = (e) => {
    const inputValue = e;
    if (inputValue) {
      setCheckinsCopy(checkins.filter((val) => {
        return (
          val?.surname?.toString().toLowerCase().search(inputValue) >= 0 || val?.first_name?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.middle_name?.toString().toLowerCase().search(inputValue) >= 0 || val?.insurance_no?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.code?.toString().toLowerCase().search(inputValue) >= 0 || val?.checkin_date?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.checkinType?.toString().toLowerCase().search(inputValue) >= 0 || val?.policy_no?.toString().toLowerCase().search(inputValue) >= 0 ||
          val?.facility_name?.toString().toLowerCase().search(inputValue) >= 0 || val?.facility_state?.toString().toLowerCase().search(inputValue) >= 0
        )
      }))
    } else {
      setCheckinsCopy(checkins);
    }
  }

  const fetchCheckins = () => {
    setLoading(true);
    myRequestObj('aCheckinList').post({ status: 0 })
      .then(res => res?.data)
      .then(data => {
        const dat = data?.data?.map(c => {
          const this_dat = {};
          Object.entries(c).forEach(col => {
            this_dat[col[0]] = col[1] || '';
          })
          return this_dat;
        })
        setCheckins(dat);
        setCheckinsCopy(dat);
      }).finally(() => setLoading(false));
  }

  // const updateCheckins = () => {
  //   clearIntervals();
  //   checkinFetchTimeout.current = setTimeout(() => {
  //     checkinFetchInterval.current = setInterval(() => {
  //       fetchCheckins()
  //     }, 3000)
  //   }, 1000)
  // }

  const clearIntervals = () => {
    clearTimeout(checkinFetchTimeout.current);
    clearInterval(checkinFetchInterval.current);
  }

  return (
    <>
      {extended && <>
        <PageTitleHook title='Encounters' style={{ color: 'blue' }} />
        {/* <FloatButton tooltip={<div>Reload</div>} onClick={fetchCheckins} icon={<ReloadOutlined />}/> */}
      </>}
        <table className="table align-middle mb-0 bg-white">
          <tbody>
            {
              extended &&
              <tr>
                <td colSpan={2}>
                  <Input style={{ width: '100%' }} placeholder='Enter your filter words ...'
                    value={filterKeyword.toLowerCase()} onChange={e => setFilterKeyword(e.target.value.toLowerCase())} />
                </td>
                {/* <td>&nbsp;</td> */}
                <td>&nbsp;</td>
                <td>{[1, 2].includes(Number(user?.role['staffRoleId'])) ? <Button type="primary" onClick={() => navigate('/file-encounter')}>File An Encounter</Button> : null}</td>
                <td>
                  <Button type="primary" onClick={fetchCheckins} color="grey">(Re)Load Encounters</Button>
                </td>
              </tr>
            }
            {
              loading ? <Loader size={100} color="#76c341" /> :
              (checkinsCopy?.length) ?
                checkinsCopy?.map(item => {
                  return (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={`${uri_img}${item?.pic_name}`}
                            alt={item?.first_name}
                            style={{ width: '45px', height: '45px' }}
                            className="rounded-circle"
                          />
                          <div className="ms-3">
                            <p className="fw-bold mb-1">{extended && item?.surname.toUpperCase()} {extended && ','} {item?.first_name} {extended && item?.middle_name}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>{extended && 'Id No.:'}</span> {item?.insurance_no}</p>
                      </td>
                      <td className=''>
                        {extended && <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Checkin Type:</span> {(item?.checkinType === 'in') ? 'In-Patient' : 'Out-Patient'}</p>}
                      </td>
                      <td className=''>
                        <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>{extended && 'Facility:'}</span> {item?.facility_name}</p>
                        {
                          extended && <>
                            <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Facility Phone:</span> {item?.facility_phone}</p>
                            <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }}>Facility Address:</span> {item?.facility_address}</p>
                          </>
                        }
                      </td>
                      <td>
                        <ClientDetails data={item} />
                      </td>
                    </tr>
                  )
                }) :
                <tr>
                  <td colSpan={4}>
                    <Empty
                      description={
                        <span>
                          There are no claims in this category
                        </span>
                      }
                      style={{ padding: 50, width: '100%' }}
                    >
                      {/* <Button type="primary">File a Claim Now</Button> */}
                    </Empty>
                  </td>
                </tr>
            }
          </tbody>
        </table>
    </>
  )
}

const ClientDetails = ({ data }) => {
  const [, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const contoller = () => {
    setVisible(true)
  }

  return (
    <>
      <Button type="dashed" danger onClick={contoller}>View Client</Button>
      <Drawer
        open={visible}
        title={<>
          <Typography.Title level={5} style={{ margin: '0 auto' }}>
            <Space>
              <Space>
                <Avatar src={`${uri_img}${data.pic_name}`} style={{ marginRight: 20 }} />
              </Space>
              <Space direction="vertical" size={'small'}>
                <Space style={{ margin: '0 auto', padding: '0 auto', height: 'fit-content' }}>
                  {data.surname.toUpperCase().trim()}, {data.first_name.trim()} {data.middle_name.trim()}
                </Space>
                <Space style={{ marginLeft: '0 auto', fontWeight: '100', fontSize: 10, padding: '0 auto', height: 'fit-content' }}>
                  {data.code} - {(new Date(data.checkin_date)).toLocaleString()}
                </Space>
              </Space>
            </Space>
          </Typography.Title>
        </>}
        onOk={handleOk}
        onCancel={handleCancel}
        closable
        // footer={[
        //   <Button key="back" onClick={handleCancel}>
        //     Close
        //   </Button>
        // ]}
        width={500}
      >
        <Typography.Title level={5} style={{ margin: '2px auto' }}>
          Bio-Data
        </Typography.Title>
        <Divider style={{ margin: '0 auto' }} />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Role: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.role}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Insurance Number: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.insurance_no}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Policy Number: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.policy_no}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Gender: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.gender}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>State / LGA: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.state.toUpperCase()} / {data.lga.toUpperCase()}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Primiry Health Care: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.primary_health_facility}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Secondary Health Care: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.secondary_health_facility}
            </Typography.Title>
          </Col>
        </Row>
        <Divider style={{ margin: '5 0' }} />
        <Typography.Title level={5} style={{ margin: '2px auto' }}>
          Facility
        </Typography.Title>
        <Divider style={{ margin: '0 auto' }} />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Facility: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.facility_name}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Facility Phone: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.facility_phone}
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Typography.Title level={5}>Facility Address: </Typography.Title>
          </Col>
          <Col span={12}>
            <Typography.Title level={5} style={{ fontWeight: 100 }}>
              {data.facility_address}
            </Typography.Title>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default CheckinListHook;