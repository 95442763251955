import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button, Row, Col, notification, Space, Table, Divider, Modal, Input } from 'antd';
import { CheckOutlined, CloseOutlined, ArrowLeftOutlined, SettingOutlined, UsergroupAddOutlined, SyncOutlined, DownloadOutlined, LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import lib, { fetchPaginatedData, myRequestObj } from "../../assets/utils/lib";
import './org.css';
import { useAuth } from "../../assets/utils/useAuth";
import Helpers, { downloadFile } from "../../assets/utils/Helpers";
import Register, { ListOfDependent } from "./profileUpdate";
import EmailVerificationModal from "../compoinents/emailVerificationModal";
import Dropdown from "../compoinents/DroptDown";
import { uri } from "../../assets/utils/http-request";
// import CurrencyFormat from "react-currency-format";

const {Search} = Input;

const OrganizationDetail = props => {
  const { org } = useParams()
  const { set, user } = useAuth();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const { state } = useLocation();

  const [orgData, setOrgData] = useState([])
  // const [view, setView] = useState('tile');
  const [focusData, setFocusData] = useState(null)
  const [showReg, setShowReg] = useState(false)
  const [showEnrolless, setShowEnrollees] = useState(false)
  const [loading, setLoading] = useState(false);
  const [loadingEnrollees, setLoadingEnrollees] = useState(false)
  const [dependentList, setDependentList] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);

  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [loadingDownloadAction, setLoadingDownloadAction] = useState(false);

  const [sharingActionPayload, setSharingActionPayload] = useState();
  
  // const [orgSearchInput, setOrgSearchinput] = useState();

  useEffect(() => {
    if (user) {
      if (![1, 3, 4, 5, 6].includes(Number(user.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigate('/login')
      }
    } else {
      navigate('/login')
    }
  })

  async function fetchAllData(url, payload = {}) {
    for await (const data of fetchPaginatedData('post', url, payload)) {
      setOrgData(d => ([...new Set([...d, ...data])]))
    }
  }

  const loadPolicies = (query = '') => {
    setOrgData([])
    setSelectedPolicies([]);
    const fet = async () => query? await fetchAllData('organizationUsers', { hash: org, query}) : await fetchAllData('organizationUsers', { hash: org});
    setLoading(true)
    fet().finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!state || !org) {
      navigator('/corporations')
      return
    }
    loadPolicies();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // const expandedRowRender = (record, index, indent, expanded) => {
  //   const columns = [
  //     {
  //       title: '',
  //       dataIndex: 'pic_name',
  //       key: 'pic_name',
  //       render: (text) => expanded && <img src={`${uri_img}${text}`} style={{ width: 50, height: 50, borderRadius: '50%' }} alt="" />
  //     },
  //     {
  //       title: 'Surname',
  //       dataIndex: 'surname',
  //       key: 'surname',
  //     },
  //     {
  //       title: 'First Name',
  //       dataIndex: 'first_name',
  //       key: 'first_name',
  //     },
  //     {
  //       title: 'Insurance No',
  //       dataIndex: 'insurance_no',
  //       key: 'insurance_no',
  //     },
  //     {
  //       title: 'Relationship',
  //       dataIndex: 'relationship',
  //       key: 'relationship',
  //     },
  //     {
  //       title: 'Age',
  //       dataIndex: 'age',
  //       render: (__, record) => calculateAge(record.date_of_birth)
  //     },
  //     {
  //       title: 'Gender / Role',
  //       dataIndex: 'gender',
  //       key: 'gender',
  //       render: (text, record) => {
  //         return <>
  //           <Tag
  //             color={text.toLowerCase().trim() === 'female' ? "error" : "success"}
  //             icon={text.toLowerCase().trim() === 'female' ? <BiFemaleSign /> : <BiMaleSign />}
  //             style={{ display: 'flex', alignItems: 'center', maxWidth: 'fit-content' }}
  //           >
  //             {text.toUpperCase()}
  //           </Tag>
  //           <Tag
  //             color={record.role.toLowerCase().trim() === 'principal' ? "error" : "success"}
  //             icon={record.role.toLowerCase().trim() === 'principal' ? <BiUser /> : <BiGroup />}
  //             style={{ display: 'flex', alignItems: 'center', maxWidth: 'fit-content' }}
  //           >
  //             {record.role.toUpperCase()}
  //           </Tag>
  //         </>
  //       }
  //     }
  //   ];

  //   const data = record.enrollees

  //   return <Table columns={columns} dataSource={data} pagination={false} indentSize={indent} />;
  // };
  const searchHandler = (value) => {
    if(value) {
      loadPolicies(value);
    }
  }

  const columns = [
    {
      title: <Row gutter={5} justify={'space-around'} align={'middle'}>
        <Col span={2}>Search:</Col> 
        <Col span={10}>
          <Search onSearch={searchHandler} allowClear/>
        </Col>
      </Row>,
      children: [
        {
          title: 'Policy Name | No.',
          dataIndex: 'name',
          key: 'name',
          render: (__, record) => <><strong>{__?.toUpperCase()}</strong> <br></br><strong>{record?.policy_no}</strong></>
        },
        {
          title: 'Premium',
          dataIndex: 'premium_type',
          key: 'premium_type',
          render: (__, record) => <>{record?.insurance_package?.toUpperCase()}<br></br>{__?.toUpperCase()}</>
        },
        {
          title: 'Enrollees (Active of Size)',
          dataIndex: 'enrolee_size',
          key: 'enrolee_size',
          render: (__, record) => <>{record?.active_enrollees} of {__}</>
        },
        {
          title: 'Date Joined',
          dataIndex: 'date',
          key: 'date',
          render: (__, record) => (new Date(__)).toDateString()
        },
        {
          title: 'Status | Action',
          key: 'status',
          render: (__, record) => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Space>{record.status ? <CheckOutlined style={{ color: 'blue' }} /> : <CloseOutlined style={{ color: 'red' }} />}</Space>
            <Divider type="vertical" style={{ border: '1px solid black' }} />
            <Space>
              <Button type="link" icon={<SettingOutlined />} onClick={() => handleView(record)} />
              <Button type="link" icon={<UsergroupAddOutlined />} onClick={() => handleViewEnrollees(record)} />
            </Space>
          </div>,
        },
      ]
    }
  ];

  const downloadOrgData = async (payload) => {
    setLoadingDownloadAction(true);
    
    notification.info({
      icon: <LoadingOutlined spin />,
      message: <>{payload?.target?.toUpperCase()} DATA IS BEING PROCESSED</>,
      key: 'download',
    });
  
    payload = { ...payload, social_no: org };
  
    try {
      const { data } = await myRequestObj('downloadOrgUsersData').post(payload);
      if (Number(data?.error) === 0) {
        notification.info({
          icon: <CheckCircleTwoTone twoToneColor="green" />,
          message: (
            <>
              <strong>{data?.message}</strong><br /><br />
              The record has been successfully processed.<br />
              {payload?.purpose === 'get' && <>Download will be processed in a moment.<br /><br /></>}
              <strong>You do not need to reinitiate this process</strong>
            </>
          ),
          key: 'download',
        });
  
        if (payload?.purpose === 'get') {
          const fileUrl = atob(data?.data);
          try {
            await downloadFile(uri + fileUrl, fileUrl.split('/')[2]);
            notification.info({
              icon: <CheckCircleTwoTone twoToneColor= 'green' />,
              message: "Download completed successfully",
              key: 'download'
            });
          } catch (error) {
            notification.info({
              icon: <CloseCircleTwoTone twoToneColor="red" />,
              message: (
                <>
                  <strong>Download Failed</strong><br /><br /> {error.message || error}
                </>
              ),
              key: 'download',
            });
          }
        }
        setIsEmailModalVisible(false);
      } else {
        notification.info({
          icon: <CloseCircleTwoTone twoToneColor="red" />,
          message: data?.message,
          key: 'download',
        });
      }
    } catch (e) {
      notification.info({
        icon: <CloseCircleTwoTone twoToneColor="red" />,
        message: e?.msg || e?.message,
        key: 'download',
      });
    } finally {
      notification.destroy('download');
      setLoadingDownloadAction(false);
    }
  };

  const handleView = (record) => {
    setFocusData(record)
    setShowReg(true)
  };

  const handleViewEnrollees = (record) => {
    setFocusData(record)
    setShowEnrollees(true)
  };

  const handleCancel = () => {
    setShowReg(false)
    setFocusData(null)
  }

  const handleCancelEnrollees = () => {
    setShowEnrollees(false)
    setFocusData(null)
    setDependentList([]);
  }

  const enrolleeListCommand = async (record) => {
    setLoadingEnrollees(true)

    let payload = {
      policy_no: record?.policy_no
    }

    lib.enrollees(payload)
      .then(resp => resp?.data)
      .then(respData => respData?.data)
      .then(data => {
        setDependentList(data)
      })
      .catch(error => {
        notification.error({
          message: error?.message || error?.msg
        })
      })
      .finally(() => setLoadingEnrollees(false))
  }

  const updateUpdatedUserData = (record, update) => {
    setOrgData(d => {
      return d.map(rec => {
        if (record?.policy_no === rec?.policy_no)
          return { ...rec, rowUpdated: update?.updateSuccess }
        return rec
      })
    })
  }

  const userDataToUpdate = (record, selected, selectedRows, nativeEvent) => {
    setOrgData(d => {
      return d.map(rec => {
        if (record?.policy_no === rec?.policy_no)
          return { ...rec, rowUpdated: selected }
        return rec
      })
    })
    setSelectedPolicies(d => {
      if (selected) return [...d, record]
      else return d.filter(v => v?.policy_no !== record?.policy_no)
    })
  }

  const rowClassName = (record) => {
    return record.rowUpdated ? 'updated-row' : '';
  };

  const handleOpenEmailModal = (payload) => {
    setSharingActionPayload(payload)
    setIsEmailModalVisible(true);
  };

  const handleCloseEmailModal = () => {
    setIsEmailModalVisible(false);
    setSharingActionPayload(null)
  };

  const handleEmailSuccess = async (email) => {
    setSharingActionPayload({...sharingActionPayload, email: email + '@amanhmo.com'})
    confirm({...sharingActionPayload, email: email + '@amanhmo.com'})
  };

  const confirm = (payload) => {
    Modal.confirm({
        title: <span><strong>SHARE ORG {payload?.target?.toUpperCase()} WITH A COLLEAGUE</strong></span>,
        icon: <ExclamationCircleOutlined />,
        content: <>
            <Row>
                {
                    Object.keys(payload).map(key => {
                        return (
                            <Col span={24} style={{ margin: '2px 0', padding: '5px 0' }}>
                                <Row justify='space-between'>
                                    <Col span={8}>{key.split('_').join(' ').toLocaleUpperCase()}:</Col>
                                    <Col span={16}>{payload[key]}</Col>
                                </Row>
                            </Col>
                        )
                    })
                }
            </Row>
            <Divider style={{ padding: 0, margin: 0 }} />
            <p style={{ color: 'red' }}><strong>Note: The data will be sent only if email is corret. You wont get a feedback otherwise.</strong></p>
            <p style={{ color: 'red' }}><strong>Are you sure you the email is correct and want to proceed?</strong></p>
        </>,
        okText: 'Yes',
        onOk: () => downloadOrgData(payload),
        cancelText: 'No',
    });
};

  const dataDwonloadMenuItems = [
    {
      key: '1',
      label: 'Download Policies',
      onClick: () => {
        downloadOrgData({target: 'policies', purpose: 'get'});
      }
    },
    {
      key: '3',
      label: 'Share Policies List',
      onClick: () => {
        handleOpenEmailModal({target: 'policies', purpose: 'share'});
      }
    },
    {
      key: '2',
      label: 'Download Enrollees',
      onClick: () => {
        downloadOrgData({target: 'enrollees', purpose: 'get'});
      }
    },
    {
      key: '4',
      label: 'Share Enrollee List',
      onClick: () => {
        handleOpenEmailModal({target: 'enrollees', purpose: 'share'});
      }
    },
    {
      key: '5',
      label: 'Share Both Lists',
      onClick: () => {
        handleOpenEmailModal({target: 'policies and enrollees', purpose: 'share'});
      }
    },
  ];

  return (
    <>
      {/* <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2em' }}>
        <Button type="primary" onClick={() => navigate('/corporations')} icon={<ArrowLeftOutlined />} style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>Back</Button>
        {state?.all_policies > 0 && <Button type="primary" onClick={downloadPolicies} icon={<UserOutlined />} style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>Download Policies</Button>}
        {state?.all_enrolees > 0 && <Button type="primary" onClick={downloadEnrolless} icon={<TeamOutlined />} style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}>Download Enrollees</Button>}
      </Space> */}
      {
        state &&
        <Row justify="start" alignItems="center" style={{ marginTop: '2em', marginBottom: '2em', padding: 10, boxShadow: "0px 0px 10px 5px #3597d3", borderRadius: 5 }}>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Organization Name:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.org_name}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Organization State:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.org_state}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Organization City:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.org_city}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Organization Code:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.org_code || state?.org_social_no}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Member Since:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.org_joined_date}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Subscription Valid Till:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.org_expiry_date}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Number of Policies:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.all_policies}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Number of Active Policies:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.active_policies}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Number of Enrollees:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.all_enrolees}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col span={24} style={{ fontWeight: 'bolder' }}><u>Number of Active Enrollees:</u></Col>
              <Col span={24} style={{ fontStyle: 'italic', fontWeight: 'bold', color: 'grey' }}>{state?.active_enrollees}</Col>
            </Row>
          </Col>
        </Row>
      }
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2em' }}>
        {!loading && <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button onClick={() => navigate('/corporations')} style={{ marginTop: 20, display: 'flex', alignItems: 'center', borderRadius: 50 }} title="Back" icon={<ArrowLeftOutlined />} />
        </Space>}
        {selectedPolicies?.length ? <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button type="primary" style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} danger onClick={() => navigate('/corporations')} title="">Update Selected</Button>
        </Space> : null}
        <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {
            <div style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} >
              <Dropdown menuItems={dataDwonloadMenuItems} icon={loadingDownloadAction? <LoadingOutlined spin /> : <DownloadOutlined />} textContent="Downloads" disabled={loadingDownloadAction}/>
              {/* <Button type="link" onClick={downloadPolicies} style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} icon={<UserOutlined />} /> */}
            </div>
          }
          {/* <Divider type="vertical" style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} /> */}
          {/* {state?.all_enrolees > 0 && !loading && <div><Button type="link" onClick={handleOpenModal} style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} title="Download Enrollees" icon={<TeamOutlined />} /></div>} */}
          <Divider type="vertical" style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} />
          {!loading && <Button type="link" style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} onClick={() => loadPolicies('*')} title="Load Policies" icon={<SyncOutlined />}>Load Policies</Button>}
        </Space>
      </div>
      <>
        {/* {view === 'tile' ? */}
        {/* <DataList data={orgData.map((val, index) => { val['key'] = index.toString(); return val })} onEdit={handleEdit} onDelete={handleDelete} onView={handleView} pageSize={12} placePagination={'both'} /> : */}
        <Table columns={columns} dataSource={orgData.map((val, index) => { val['key'] = index.toString(); return val })} loading={loading} rowClassName={rowClassName}
          rowSelection={{ hideSelectAll: true, onSelect: userDataToUpdate }} showHeader
        />
        {/* } */}
      </>
      {showReg && <Register user={focusData} handleCancel={handleCancel} isModalVisible={showReg} command={updateUpdatedUserData} />}
      {showEnrolless && <ListOfDependent dependentList={dependentList} user={focusData} command={() => enrolleeListCommand(focusData)} showModal={showEnrolless} closeModal={handleCancelEnrollees} loading={loadingEnrollees} />}
      {isEmailModalVisible && (<EmailVerificationModal domain="amanhmo.com" onSuccess={handleEmailSuccess} onCancel={handleCloseEmailModal} payload={sharingActionPayload} title="Orgnizaion Data Request"/>)}
    </>
  )
}

export default OrganizationDetail;